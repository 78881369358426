// 预估成功率变更记录
// popSuccessPercentRecord
<template>

    <div class="popSuccessPercentRecord">
        <el-dialog title="预估成功率变更记录"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="700px"
                   :before-close="close">
            <el-table :data="tableData.data"
                      border
                      stripe>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                    <template slot-scope="scope">
                        <span v-if="col.id === 'percent'">
                            {{scope.row[col.id]}}
                            <i class="el-icon-top green percentRecord"
                               v-if="scope.row.upOrDown"></i>
                            <i class="el-icon-bottom red percentRecord"
                               v-else-if="scope.row.upOrDown != null"></i>
                        </span>
                        <span v-else>{{scope.row[col.id]}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/reserveProject.js";
export default {
    name: "popSuccessPercentRecord",

    props: {
        // 项目机会id
        reserveProjectId: {
            type: String,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,

            tableData: {
                data: [],
                columns: [
                    { id: "createTime", label: "变更时间", width: "100" },
                    { id: "createUserName", label: "操作人", width: "100" },
                    { id: "beforePercent", label: "变更前", width: "100" },
                    { id: "percent", label: "变更后", width: "100" },
                    { id: "content", label: "变更原因", width: "200" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            API.followReserve({
                changType: ["PERCENT", "BOTH"],
                reserveProjectId: this.reserveProjectId,
            })
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    data.forEach((item) => {
                        item.beforePercent = item.beforePercent + "%";
                        item.percent = item.percent + "%";
                        item.upOrDown = item.beforePercent
                            ? item.percent > item.beforePercent
                            : null;
                    });
                    this.tableData.data = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.percentRecord {
    font-size: 16px;
}
</style>