import { render, staticRenderFns } from "./tableLose.vue?vue&type=template&id=f015fb12&scoped=true&"
import script from "./tableLose.vue?vue&type=script&lang=js&"
export * from "./tableLose.vue?vue&type=script&lang=js&"
import style0 from "./tableLose.vue?vue&type=style&index=0&id=f015fb12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f015fb12",
  null
  
)

export default component.exports