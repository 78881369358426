// 跟进中 - 列表
<template>

    <div class="tableFollowUp">

        <!-- 搜索条件 -->
        <div class="searchForm">
            <el-form :model="searchForm"
                     ref="searchForm"
                     :inline="true">
                <el-form-item label="机会名称"
                              prop="name">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入机会名称"></el-input>
                </el-form-item>
                <el-form-item label="客户"
                              prop="customerCompanyId">
                    <selectTree :treeData="treeData"
                                ref="selectTree"
                                :shouldClean="true"
                                placeholderStr="请选择客户"
                                :checkedInfo="{value: searchForm.customerCompanyId}"
                                @handClear="handClear"
                                @treeSelectNodeClick="projectCustClick"></selectTree>
                </el-form-item>
                <el-form-item label="最近跟进时间"
                              prop="unFollowType">
                    <el-select v-model="searchForm.unFollowType"
                               placeholder="最近跟进时间"
                               clearable>
                        <el-option v-for="item in unFollowTypeOpts"
                                   :key="item.code"
                                   :label="item.label"
                                   :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <template v-if="showMoreSearch">
                    <el-form-item label="软件信息"
                                  prop="systemsInvolvedId">
                        <el-select v-model="searchForm.systemsInvolvedId"
                                   placeholder="请选择软件信息"
                                   clearable>
                            <el-option v-for="item in systemInfoIdOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="落单年份"
                                  prop="successYear">
                        <el-date-picker v-model="searchForm.successYear"
                                        value-format="yyyy年"
                                        format="yyyy年"
                                        type="year"
                                        placeholder="请选择落单年份">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="机会类型"
                                  prop="type">
                        <el-select v-model="searchForm.type"
                                   placeholder="请选择机会类型"
                                   clearable>
                            <el-option v-for="item in itemTypeOpts"
                                       :key="item.code"
                                       :label="item.label"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="内部立项时间"
                                  prop="startTimeLeft">
                        <el-date-picker v-model="searchForm.startTimeLeft"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="请选择内部立项时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="~"
                                  prop="startTimeRight">
                        <el-date-picker v-model="searchForm.startTimeRight"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="请选择项目结束时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="商务负责人"
                                  prop="businessManagerIds">
                        <el-select v-model="searchForm.businessManagerIds"
                                   placeholder="商务负责人"
                                   multiple
                                   clearable>
                            <el-option v-for="item in businessManagerOpts"
                                       :key="item.code"
                                       :label="item.label"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="售前负责人"
                                  prop="saleManagerIds">
                        <el-select v-model="searchForm.saleManagerIds"
                                   placeholder="请选择售前负责人"
                                   multiple
                                   clearable>
                            <el-option v-for="item in saleManagerOpts"
                                       :key="item.code"
                                       :label="item.label"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目负责人"
                                  prop="projectManagerIds">
                        <el-select v-model="searchForm.projectManagerIds"
                                   placeholder="请选择项目负责人"
                                   multiple
                                   clearable>
                            <el-option v-for="item in projectManagerOpts"
                                       :key="item.code"
                                       :label="item.label"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                    <el-button @click="resetSearchForm">清除</el-button>
                </el-form-item>

                <!-- 搜索栏是否显示更多 -->
                <div class="showMoreSearchBox">
                    <span :class="showMoreSearch ? 'close' : 'open'"
                          @click="showMoreSearch = !showMoreSearch">{{showMoreSearch ? '收起' : '展开'}}搜索</span>
                </div>

            </el-form>
        </div>

        <!-- 控制条 -->
        <div class="controlBar">
            <p class="fll red">
                <span class="mr55">预估金额总计：{{calculateInfo.totalMoney}}万元</span>
                <span class="mr55">加权折算总计：{{calculateInfo.totalCalculateMoney}}万元</span>
            </p>
            <el-button type="primary"
                       v-role="commonJs.getBtnRoles(detailRole,'Btn_新增增补项目')"
                       @click="addSupplementProject">新增增补项目</el-button>
            <el-button type="primary"
                       v-role="commonJs.getBtnRoles(detailRole,'Btn_新增储备项目')"
                       @click="addReserveProject">新增项目机会</el-button>
        </div>

        <!-- 列表 -->
        <div class="tableBox">
            <el-table :data="tableData.data"
                      :height="tableHeight"
                      border
                      stripe>
                <el-table-column type="index"
                                 label="序号"
                                 align="center"
                                 width="50">
                </el-table-column>
                <el-table-column prop="establishTime"
                                 label="内部立项日期"
                                 align="center"
                                 min-width="130"
                                 width="130">
                </el-table-column>
                <el-table-column prop="successYear"
                                 label="落单年份"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.successYear"
                                          title="落单年份"
                                          width="220"
                                          :scope="scope">
                            <el-date-picker v-model="searchForm.successYear"
                                            value-format="yyyy年"
                                            format="yyyy年"
                                            type="year"
                                            @change="getList(1)"
                                            placeholder="请选择落单年份">
                            </el-date-picker>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="name"
                                 label="机会名称"
                                 align="center"
                                 min-width="200"
                                 width="auto">
                </el-table-column>
                <el-table-column prop="type"
                                 label="机会类型"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.type"
                                          title="机会类型"
                                          :scope="scope">
                            <el-select v-model="searchForm.type"
                                       placeholder="请选择机会类型"
                                       @change="getList(1)"
                                       clearable>
                                <el-option v-for="item in itemTypeOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="customerCompanyName"
                                 label="客户"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.customerCompanyId"
                                          title="客户"
                                          width="350"
                                          :scope="scope">
                            <selectTree :treeData="treeData"
                                        ref="selectTree"
                                        :shouldClean="true"
                                        placeholderStr="请选择客户"
                                        :checkedInfo="{value: searchForm.customerCompanyId}"
                                        @handClear="handClear"
                                        @treeSelectNodeClick="projectCustClick"></selectTree>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="money"
                                 label="预估价格（万元）"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                </el-table-column>
                <el-table-column prop="successPercent"
                                 label="预估成功率"
                                 align="center"
                                 min-width="120"
                                 width="auto">
                    <template slot="header">
                        <span>预估成功率
                            <el-tooltip class="item"
                                        effect="dark">
                                <i class="el-icon-question"></i>
                                <div slot="content">
                                    预估金额与成功率预估标准：项目预估成功率按照项目的不同阶段、跟进情况和商务资源情况进行不断调整，具体要求如下：<br>
                                    1、初次交流阶段：报价为0，成功率0%，<br>
                                    2、项目立项阶段：报价为实际报价，成功率10%<br>
                                    3、项目招标阶段：报价为招标价格，成功率为按照客户商务情况填报，稳定长期客户按照80%填报，其他客户按照10%填报；<br>
                                    4、项目中标阶段：报价为中标价格，成功率为100%<br>
                                </div>
                            </el-tooltip>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        <div class="warp">
                            <template v-if="scope.row.successPercent != null">
                                {{scope.row.successPercent}}%
                                <i class="el-icon-top green percentRecord"
                                   v-if="scope.row.upOrDown"
                                   @click="openpopSuccessPercentRecord(scope.row)"></i>
                                <i class="el-icon-bottom red percentRecord"
                                   v-else-if="scope.row.upOrDown != null"
                                   @click="openpopSuccessPercentRecord(scope.row)"></i>
                            </template>
                        </div>
                    </template>

                </el-table-column>
                <el-table-column prop="calculateMoney"
                                 label="加权折算（万元）"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                    <template slot-scope="scope">
                        <div class="warp">
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="加权折算=预估价格*项估成功率"
                                        placement="top-start">
                                <span>{{scope.row.calculateMoney}}</span>
                            </el-tooltip>
                        </div>
                    </template>

                </el-table-column>
                <el-table-column prop="latestFollowTime"
                                 label="最近跟进"
                                 align="center"
                                 min-width="130"
                                 width="130">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.unFollowType"
                                          title="最近跟进时间"
                                          :scope="scope">
                            <el-select v-model="searchForm.unFollowType"
                                       placeholder="最近跟进时间"
                                       @change="getList(1)"
                                       clearable>
                                <el-option v-for="item in unFollowTypeOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="businessManagers"
                                 label="商务负责人"
                                 align="center"
                                 min-width="115"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.businessManagerIds"
                                          title="商务负责人"
                                          :scope="scope">
                            <el-select v-model="searchForm.businessManagerIds"
                                       placeholder="商务负责人"
                                       multiple
                                       @change="getList(1)"
                                       clearable>
                                <el-option v-for="item in businessManagerOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="saleManagers"
                                 label="售前负责人"
                                 align="center"
                                 min-width="115"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.saleManagerIds"
                                          title="售前负责人"
                                          :scope="scope">
                            <el-select v-model="searchForm.saleManagerIds"
                                       placeholder="请选择售前负责人"
                                       multiple
                                       @change="getList(1)"
                                       clearable>
                                <el-option v-for="item in saleManagerOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="projectManagers"
                                 label="项目负责人"
                                 align="center"
                                 min-width="115"
                                 width="auto">
                    <template slot="header"
                              slot-scope="scope">
                        <el-popoverHeader v-model="searchForm.projectManagerIds"
                                          title="项目负责人"
                                          :scope="scope">
                            <el-select v-model="searchForm.projectManagerIds"
                                       placeholder="请选择项目负责人"
                                       multiple
                                       @change="getList(1)"
                                       clearable>
                                <el-option v-for="item in projectManagerOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-popoverHeader>
                    </template>
                </el-table-column>
                <el-table-column prop="sysNames"
                                 label="软件信息"
                                 align="center"
                                 min-width="100"
                                 width="auto">
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 fixed="right"
                                 min-width="100">
                    <template slot-scope="scope">
                        <el-link type="primary"
                                 v-if="commonJs.isShowBtn(detailRole,'Btn_详情')"
                                 @click="details(scope.row)">查看详情</el-link>
                        <el-popconfirm confirm-button-text="删除"
                                       cancel-button-text="取消"
                                       icon="el-icon-info"
                                       icon-color="red"
                                       @confirm="del(scope.row.id)"
                                       title="真的要删除吗？">
                            <el-link type="danger"
                                     slot="reference">删除</el-link>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination :page="searchForm.page"
                        :limit="searchForm.size"
                        :total="pageTotal"
                        @pagination="paginationChange" />
        </div>

        <!-- 预估成功率变更记录 -->
        <pop-success-percent-record v-if="popSuccessPercentRecordVisible"
                                    :reserveProjectId="reserveProjectId"
                                    @close="closepopSuccessPercentRecord"></pop-success-percent-record>

    </div>

</template>

<script>
import PopSuccessPercentRecord from "../popSuccessPercentRecord";
import { Loading } from "element-ui";
import selectTree from "@/components/selectTree";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/reserveProject.js";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "tableFollowUp",

    props: {
        detailRole: {
            // 数据权限
            type: Object,
        },
    },

    components: {
        PopSuccessPercentRecord,
        selectTree,
        Pagination,
    },

    data() {
        return {
            showMoreSearch: false, // 是否显示更多搜索条件

            searchForm: {
                name: "", // 机会名称
                customerCompanyId: "", // 客户
                systemsInvolvedId: "", // 软件信息
                successYear: null, // 落单年份
                startTimeLeft: "", // 内部立项时间-开始
                startTimeRight: "", // 内部立项时间-开始
                latestFollowStartTime: "", // 最近跟进时间
                status: this.$store.state.reserveProject.projectStatus.filter(
                    (item) => item.label === "跟进中"
                )[0].code,
                type: "", // 机会类型
                businessManagerIds: [], // 商务负责人
                saleManagerIds: [], // 售前负责人
                projectManagerIds: [], // 项目负责人
                unFollowType: "", // 最近跟进时间
                order: "DESC",
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            systemInfoIdOpts: [{ label: "全部", value: "" }], // 软件信息下拉
            itemTypeOpts: [
                {
                    code: "",
                    label: "全部",
                },
                {
                    code: "CHANCE",
                    label: "项目机会",
                    isDefault: false,
                },
                {
                    code: "RESERVE",
                    label: "储备项目",
                    isDefault: false,
                },
                {
                    code: "AUGMENT",
                    label: "增补项目",
                    isDefault: false,
                },
            ], // 机会类型下拉
            businessManagerOpts: this.commonJs.getPersonOpts(
                "BUSINESS",
                "businessManagerOpts",
                this,
                "noDefault"
            ), // 商务负责人
            saleManagerOpts: this.commonJs.getPersonOpts(
                "SALE",
                "saleManagerOpts",
                this,
                "noDefault"
            ), // 售前负责人
            projectManagerOpts: this.commonJs.getPersonOpts(
                "PROJECT",
                "projectManagerOpts",
                this,
                "noDefault"
            ), // 项目负责人
            treeData: [], // 客户下拉树

            unFollowTypeOpts: this.commonJs.getSelectByType(
                "UnFollowType",
                "unFollowTypeOpts",
                this,
                true
            ), // 最近跟进时间
            popSuccessPercentRecordVisible: false, // 预估成功率变更记录弹窗显示

            reserveProjectId: "", // 点击上下箭头的项目机会id
            calculateInfo: {
                businessManagerIds: "",
                saleManagerIds: "",
                projectManagerIds: "",
            }, // 计算bar信息
            tableData: {
                data: [],
            },
        };
    },

    created() {},

    mounted() {
        console.log("子组件mounted");
        // 查询软件信息下拉
        this.getSystemInfoList();
        // 客户下拉树
        this.getCustomerSelectTree();
        if (window.localStorage.getItem("reserveProjectTabId") !== "null") {
            // 查询列表
            this.getList();
        }
    },

    methods: {
        // 查询软件信息下拉
        getSystemInfoList() {
            commonAPI
                .getSystemInfoList()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.systemInfoIdOpts = this.systemInfoIdOpts.concat(opt);
                })
                .catch(() => {});
        },
        // 客户下拉树
        getCustomerSelectTree() {
            commonAPI
                .getCustomerSelectTree({ type: "CUSTOMER" })
                .then((res) => {
                    this.treeData = res.content;
                })
                .catch(() => {
                    this.$message({
                        message: "获取客户失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 清除下拉树选择
                    this.$refs.selectTree.cleanSelect();
                    this.searchForm.businessManagerIds = []; // 商务负责人
                    this.searchForm.saleManagerIds = []; // 售前负责人
                    this.searchForm.projectManagerIds = []; // 项目负责人
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 查询列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = Object.assign({}, this.searchForm);
            console.log(window.localStorage.getItem("tabId"));
            API.reserveProjectList(data)
                .then((res) => {
                    console.log("bbbbbbbbbbbbbbb");
                    loading.close();
                    let data = res.content.records;
                    data.forEach((item) => {
                        item.itemIngCount = `${item.finishProjectNum}/${item.allProjectNum}`;
                        item.type = item.type.text;
                        item.businessManagers = item.businessManagers
                            ? item.businessManagers
                            : "无";
                        item.sysNames = item.sysNames ? item.sysNames : "无";
                        item.saleManagers = item.saleManagers
                            ? item.saleManagers
                            : "无";
                        item.projectManagers = item.projectManagers
                            ? item.projectManagers
                            : "无";
                        item.upOrDown = item.beforePercent
                            ? item.successPercent > item.beforePercent
                            : null;
                    });
                    // 计算bar信息
                    this.calculateInfo = {
                        totalCalculateMoney: res.content.totalCalculateMoney,
                        totalMoney: res.content.totalMoney,
                        totalSuccessMoney: res.content.totalSuccessMoney,
                    };

                    this.tableData.data = data;
                    this.pageTotal = res.content.total;
                    // 更新count
                    this.$emit("update", "跟进中", {
                        totalCount: res.content.total,
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        //客户的选择
        projectCustClick(node) {
            this.searchForm.customerCompanyId = node.id;
            this.getList();
        },

        //客户的清空
        handClear() {
            this.searchForm.customerCompanyId = "";
            this.getList();
        },
        // 查看详情
        details(row) {
            window.localStorage.setItem("reserveProjectTab", "followIng");
            this.$router.push({
                path: "/reserveProject/reserveProjectDetails",
                query: {
                    status: "编辑",
                    step: "跟进中",
                    id: row.id,
                },
            });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 预估成功率变更记录 打开弹框
        openpopSuccessPercentRecord(item) {
            this.reserveProjectId = item.id;
            this.popSuccessPercentRecordVisible = true;
        },
        // 预估成功率变更记录 关闭弹框
        closepopSuccessPercentRecord() {
            this.reserveProjectId = null;
            this.popSuccessPercentRecordVisible = false;
        },
        // 新增增补项目
        addSupplementProject() {
            this.$router.push({
                path: "/reserveProject/reserveProjectDetails",
                query: {
                    status: "新增",
                    step: "全部",
                    type: "AUGMENT",
                },
            });
        },
        // 新增项目机会
        addReserveProject() {
            this.$router.push({
                path: "/reserveProject/reserveProjectDetails",
                query: {
                    status: "新增",
                    step: "全部",
                    type: "CHANCE",
                },
            });
        },
        // 删除储备项目
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delReserveItem(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 335 });
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.tableFollowUp .searchForm {
    height: auto;
}
// 预估成功率记录弹窗
.percentRecord {
    cursor: pointer;
    font-size: 20px;
    transition: all 0.6s;
}
.percentRecord:hover {
    opacity: 0.8;
    transition: all 0.6s;
}
.tableFollowUp ::v-deep .searchForm .el-input,
.tableFollowUp ::v-deep.searchForm .el-date-editor {
    width: 195px !important;
}
</style>
