// 项目机会管理
<template>

    <div class='reserveProject baseBg'>
        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="handleClick">
                <el-tab-pane name="all"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_全部')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_全部')"
                         slot="label">
                        <el-badge :type="activeName == '全部' ? 'primary' : 'warning'"
                                  :value="counts['全部']"
                                  class="item">
                            全部
                        </el-badge>
                    </div>
                    <!-- 全部 列表组件 -->
                    <table-all v-if="activeName == 'all'"
                               ref="all"
                               :detailRole="detailRole"
                               @update="update"></table-all>
                </el-tab-pane>

                <el-tab-pane name="followIng"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_跟进中')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_跟进中')"
                         slot="label">
                        <el-badge :type="activeName == '跟进中' ? 'primary' : 'warning'"
                                  :value="counts['跟进中']"
                                  class="item">
                            跟进中
                        </el-badge>
                    </div>
                    <!-- 跟进中 列表组件 -->
                    <table-follow-up v-if="activeName == 'followIng'"
                                     ref="followIng"
                                     :detailRole="detailRole"
                                     @update="update"></table-follow-up>
                </el-tab-pane>

                <el-tab-pane name="success"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_成功')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_成功')"
                         slot="label">
                        <el-badge :type="activeName == '成功' ? 'primary' : 'warning'"
                                  :value="counts['成功']"
                                  class="item">
                            成功
                        </el-badge>
                    </div>
                    <!-- 成功 列表组件 -->
                    <table-success v-if="activeName == 'success'"
                                   ref="success"
                                   :detailRole="detailRole"
                                   @update="update"></table-success>
                </el-tab-pane>

                <el-tab-pane name="hangUp"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_挂起')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_挂起')"
                         slot="label">
                        <el-badge :type="activeName == '挂起' ? 'primary' : 'warning'"
                                  :value="counts['挂起']"
                                  class="item">
                            挂起
                        </el-badge>
                    </div>
                    <!-- 挂起 列表组件 -->
                    <table-hang-up v-if="activeName == 'hangUp'"
                                   ref="hangUp"
                                   :detailRole="detailRole"
                                   @update="update"></table-hang-up>
                </el-tab-pane>

                <el-tab-pane name="giveUp"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_放弃')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_放弃')"
                         slot="label">
                        <el-badge :type="activeName == '放弃' ? 'primary' : 'warning'"
                                  :value="counts['放弃']"
                                  class="item">
                            放弃
                        </el-badge>
                    </div>
                    <!-- 放弃 列表组件 -->
                    <table-give-up v-if="activeName == 'giveUp'"
                                   ref="giveUp"
                                   :detailRole="detailRole"
                                   @update="update"></table-give-up>
                </el-tab-pane>

                <el-tab-pane name="lose"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_战败')">
                    <div class="tabsLabel"
                         @click="commonJs.setTabId(detailRole,'Tab_战败')"
                         slot="label">
                        <el-badge :type="activeName == '战败' ? 'primary' : 'warning'"
                                  :value="counts['战败']"
                                  class="item">
                            战败
                        </el-badge>
                    </div>
                    <!-- 战败 列表组件 -->
                    <table-lose v-if="activeName == 'lose'"
                                ref="lose"
                                :detailRole="detailRole"
                                @update="update"></table-lose>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>

</template>

<script>
import TableLose from "./components/tableLose/tableLose";
import TableGiveUp from "./components/tableGiveUp/tableGiveUp";
import TableHangUp from "./components/tableHangUp/tableHangUp";
import TableSuccess from "./components/tableSuccess/tableSuccess";
import TableFollowUp from "./components/tableFollowUp/tableFollowUp";
import TableAll from "./components/tableAll/tableAll";
import API from "@/api/reserveProject.js";
import { Loading } from "element-ui";
// 阶段状态 前端用
// all - 全部 Tab_全部
// followIng - 跟进中 Tab_跟进中
// success - 成功 Tab_成功
// hangUp - 挂起 Tab_挂起
// giveUp - 放弃 Tab_放弃
// lose - 战败 Tab_战败
export default {
    name: "reserveProject",

    props: [],

    components: {
        TableLose,
        TableGiveUp,
        TableHangUp,
        TableSuccess,
        TableFollowUp,
        TableAll,
    },

    data() {
        return {
            activeName: "",
            counts: {}, // 列表各状态计数
            detailRole: {}, //权限数据
        };
    },

    created() {
        // console.log("created");
        // this.getFistTab();
    },

    /* 组件缓存触发 */
    activated() {
        !window.localStorage.getItem("reserveProjectTabId") &&
            this.getFistTab();
        //     this.getFistTab();
        //     console.log("activated");
        //     this.commonJs.getFistTabId(this.detailRole, [
        //         "Tab_全部",
        //         "Tab_跟进中",
        //         "Tab_成功",
        //         "Tab_挂起",
        //         "Tab_放弃",
        //         "Tab_战败",
        //     ]);
        //     this.commonJs.getCurrentButtonByMenuId(
        //         window.localStorage.getItem("routerId")
        //     );
        //     // 获取列表各状态计数
        //     this.reserveProjectCount();
    },

    mounted() {
        this.commonJs.getCurrentButtonByMenuId(
            window.localStorage.getItem("routerId")
        );
        // 获取列表各状态计数
        this.reserveProjectCount();
        this.getFistTab();
        console.log(
            "mounted",
            this.activeName,
            window.localStorage.getItem("tabId")
        );
    },

    methods: {
        /**
         * 项目机会管理第一个有权限的Tab
         * 由于项目管理拆分组件比较特殊，需要单独遍历
         */
        getFistTab() {
            var name = [
                "Tab_全部",
                "Tab_跟进中",
                "Tab_成功",
                "Tab_挂起",
                "Tab_放弃",
                "Tab_战败",
            ];
            for (var i = 0; i <= name.length; i++) {
                let filter = this.detailRole.buttonMenus.filter(
                    (v) => v.title === name[i]
                );
                let rolebtn = filter.length && filter[0];
                if (rolebtn && rolebtn.checked) {
                    window.localStorage.setItem(
                        "tabId",
                        rolebtn ? rolebtn.id : null
                    );
                    window.localStorage.setItem(
                        "reserveProjectTabId",
                        rolebtn ? rolebtn.id : null
                    );
                    this.activeName = [
                        "all",
                        "followIng",
                        "success",
                        "hangUp",
                        "giveUp",
                        "lose",
                    ][i];
                    break;
                }
            }
        },
        // 获取列表各状态计数
        reserveProjectCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-badge"),
            });
            API.reserveProjectCount()
                .then((res) => {
                    loading.close();
                    this.counts = res.content;
                })
                .catch(() => {
                    this.$message({
                        message: "获取列表数量失败，请刷新后重试！",
                        type: "error",
                    });
                    loading.close();
                });
        },
        handleClick(tab) {
            /*
             * 解题思路:
             * 1.每个tab绑定一个信息，点击保存到localStorage ✔
             * 2.切回到列表时，判断localStorage内currentTab是否是该模块的
             * 3.如果是就判断是否有对应权限
             * 4.有就跳到上次存储的位置，无就按原有逻辑
             */
            let tabInfos = {
                all: {
                    name: "Tab_全部",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_全部") ||
                        null,
                    menuName: "项目机会管理",
                },
                followIng: {
                    name: "Tab_跟进中",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_跟进中") ||
                        null,
                    menuName: "项目机会管理",
                },
                success: {
                    name: "Tab_成功",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_成功") ||
                        null,
                    menuName: "项目机会管理",
                },
                hangUp: {
                    name: "Tab_挂起",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_挂起") ||
                        null,
                    menuName: "项目机会管理",
                },
                giveUp: {
                    name: "Tab_放弃",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_放弃") ||
                        null,
                    menuName: "项目机会管理",
                },
                lose: {
                    name: "Tab_战败",
                    tabId:
                        this.commonJs.getTabId(this.detailRole, "Tab_战败") ||
                        null,
                    menuName: "项目机会管理",
                },
            };
            window.localStorage.setItem(
                "activeTab",
                tabInfos[tab.name] ? JSON.stringify(tabInfos[tab.name]) : null
            );
            window.localStorage.setItem(
                "reserveProjectTabId",
                tabInfos[tab.name] ? tabInfos[tab.name].tabId : null
            );
            // this.$nextTick(() => {
            //     if (this.$refs[tab.name] !== undefined) {
            //         this.$refs[tab.name].getList();
            //     }
            // });
        },
        // 更新计数
        update(ref, data) {
            this.counts[ref] = data.totalCount;
        },
    },

    computed: {
        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "项目机会管理_列表"
                );
            },
        },
    },
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
.reserveProject ::v-deep {
    .searchForm {
        .el-input,
        .el-date-editor {
            width: 200px;
        }
    }
}
</style>
